@font-face {
	font-family: 'roboto-medium';
	src: url('fonts/Roboto-Medium.eot');
	src: local('☺'), url('fonts/Roboto-Medium.woff') format('woff'), url('fonts/Roboto-Medium.ttf') format('truetype'), url('fonts/Roboto-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'roboto-black';
	src: url('fonts/Roboto-Black.eot');
	src: local('☺'), url('fonts/Roboto-Black.woff') format('woff'), url('fonts/Roboto-Black.ttf') format('truetype'), url('fonts/Roboto-Black.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}


@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

$primary: #000000;
$secondary: #373737;
$c1: #ffbb00;
$c2: #ff0381;
$c3: #b000ff;
$c4: #3a89ff;
$c5: #6b6b6b;
$c6: #282827;
$c7: #56db5f;
$c8: #ff5b71;
$white: #fff;
$black: #000000;

$digit1: 5px;
$digit2: 10px;
$digit3: 15px;
$digit4: 20px;
$digit5: 25px;
$digit6: 30px;
$digit7: 35px;
$digit8: 40px;
$digit9: 45px;
$digit10: 50px;
$digit11: 55px;
$digit12: 60px;
$digit13: 65px;
$digit14: 70px;
$digit15: 75px;
$digit16: 80px;
$digit17: 85px;
$digit18: 90px;
$digit19: 95px;
$digit20: 100px;

$w100p: 100%;
$zero: 0;

$spaceamounts: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
/* padding margin function*/

/* Text Align */
$align-list: left right center;
@each $align-txt in $align-list {
  .align-#{$align-txt} {
    text-align: $align-txt !important;
  }
}
%black-background-opacity1 {
  background: rgba($black, 0.7);
}

%black-background-opacity {
  background: rgba($black, 0.8);
}

%grey-gradiant-background {
  background: #979797;
  background: -moz-linear-gradient(
    left,
    #979797 0%,
    #fefefe 12%,
    #dcdcdc 24%,
    #7d7d7d 41%,
    #5a5a5a 58%,
    #7d7d7d 74%,
    #c4c4c4 86%,
    #f8f8f8 99%
  );
  background: -webkit-linear-gradient(
    left,
    #979797 0%,
    #fefefe 12%,
    #dcdcdc 24%,
    #7d7d7d 41%,
    #5a5a5a 58%,
    #7d7d7d 74%,
    #c4c4c4 86%,
    #f8f8f8 99%
  );
  background: linear-gradient(
    to right,
    #979797 0%,
    #fefefe 12%,
    #dcdcdc 24%,
    #7d7d7d 41%,
    #5a5a5a 58%,
    #7d7d7d 74%,
    #c4c4c4 86%,
    #f8f8f8 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#979797', endColorstr='#f8f8f8',GradientType=1 );
}

%redYellow-border {
  border:5px solid;
  -moz-border-image: -moz-linear-gradient(right, #ffbe00 0%, #ff0381 99%);
  -webkit-border-image: -webkit-linear-gradient(right, #ffbe00 0%, #ff0381 99%);
  border-image: linear-gradient(to left, #ffbe00 0%, #ff0381 99%);
  border-image-slice: 1;
}

%redYellow-border-rotate {
  border:5px solid;
  -moz-border-image: -moz-linear-gradient(right, #ffbe00 0%, #ff0381 99%);
  -webkit-border-image: -webkit-linear-gradient(right, #ffbe00 0%, #ff0381 99%);
  border-image: linear-gradient(to left, #ffbe00 0%, #ff0381 99%);
  border-image-slice: 1;
}
%redYellow-border-rotate-thin {
  border:3px solid;
  -moz-border-image: -moz-linear-gradient(right, #ffbe00 0%, #ff0381 99%);
  -webkit-border-image: -webkit-linear-gradient(right, #ffbe00 0%, #ff0381 99%);
  border-image: linear-gradient(to left, #ffbe00 0%, #ff0381 99%);
  border-image-slice: 1;
}

%gray-border {
  border:5px solid;
  -moz-border-image: -moz-linear-gradient(
    left,
    #979797 0%,
    #fefefe 12%,
    #dcdcdc 24%,
    #7d7d7d 41%,
    #5a5a5a 58%,
    #7d7d7d 74%,
    #c4c4c4 86%,
    #f8f8f8 99%
  );
  -webkit-border-image: -webkit-linear-gradient(
    left,
    #979797 0%,
    #fefefe 12%,
    #dcdcdc 24%,
    #7d7d7d 41%,
    #5a5a5a 58%,
    #7d7d7d 74%,
    #c4c4c4 86%,
    #f8f8f8 99%
  );
  border-image: linear-gradient(
    to right,
    #979797 0%,
    #fefefe 12%,
    #dcdcdc 24%,
    #7d7d7d 41%,
    #5a5a5a 58%,
    #7d7d7d 74%,
    #c4c4c4 86%,
    #f8f8f8 99%
  );
  border-image-slice: 1;
}
%perpule-background {
  background: #b000ff;
  background: -moz-linear-gradient(left, #b000ff 0%, #3a89ff 99%);
  background: -webkit-linear-gradient(left, #b000ff 0%, #3a89ff 99%);
  background: linear-gradient(to right, #b000ff 0%, #3a89ff 99%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b000ff', endColorstr='#3a89ff',GradientType=1 );
}
%perpule-background-up-down {
  background: rgba(58, 137, 255, 1);
  background: -moz-linear-gradient(
    top,
    rgba(58, 137, 255, 1) 0%,
    rgba(174, 0, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(58, 137, 255, 1)),
    color-stop(100%, rgba(174, 0, 255, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(58, 137, 255, 1) 0%,
    rgba(174, 0, 255, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(58, 137, 255, 1) 0%,
    rgba(174, 0, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(58, 137, 255, 1) 0%,
    rgba(174, 0, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(58, 137, 255, 1) 0%,
    rgba(174, 0, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3a89ff', endColorstr='#ae00ff', GradientType=0 );
}
%orange-background {
  background: #ffbe00;
  background: -moz-linear-gradient(top, #ffbe00 0%, #ff0381 99%);
  background: -webkit-linear-gradient(top, #ffbe00 0%, #ff0381 99%);
  background: linear-gradient(to bottom, #ffbe00 0%, #ff0381 99%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbe00', endColorstr='#ff0381',GradientType=0 );
}
%body-background {
  background: rgba(16, 18, 26, 1);
  background: -moz-linear-gradient(
    top,
    rgba(16, 18, 26, 1) 0%,
    rgba(14, 29, 63, 1) 23%,
    rgba(155, 77, 158, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(16, 18, 26, 1)),
    color-stop(23%, rgba(14, 29, 63, 1)),
    color-stop(100%, rgba(155, 77, 158, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(16, 18, 26, 1) 0%,
    rgba(14, 29, 63, 1) 23%,
    rgba(155, 77, 158, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(16, 18, 26, 1) 0%,
    rgba(14, 29, 63, 1) 23%,
    rgba(155, 77, 158, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(16, 18, 26, 1) 0%,
    rgba(14, 29, 63, 1) 23%,
    rgba(155, 77, 158, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(16, 18, 26, 1) 0%,
    rgba(14, 29, 63, 1) 23%,
    rgba(155, 77, 158, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10121a', endColorstr='#9b4d9e', GradientType=0 );
}

%pinkYellow-background {
  background: rgba(255, 3, 129, 1);
  background: -moz-linear-gradient(
    45deg,
    rgba(255, 3, 129, 1) 0%,
    rgba(255, 191, 0, 1) 100%
  );
  background: -webkit-gradient(
    left bottom,
    right top,
    color-stop(0%, rgba(255, 3, 129, 1)),
    color-stop(100%, rgba(255, 191, 0, 1))
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(255, 3, 129, 1) 0%,
    rgba(255, 191, 0, 1) 100%
  );
  background: -o-linear-gradient(
    45deg,
    rgba(255, 3, 129, 1) 0%,
    rgba(255, 191, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    45deg,
    rgba(255, 3, 129, 1) 0%,
    rgba(255, 191, 0, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(255, 3, 129, 1) 0%,
    rgba(255, 191, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0381', endColorstr='#ffbf00', GradientType=1 );
}

%back-background {
  position: absolute;
  top: $digit2;
  left: $digit2;
  background: transparent;
  border: $zero;
  width: $digit8;
  height: $digit8;
  padding: $zero;
}

%circleBox {
  border-radius: $digit16;
  float: left;
}
